import { graphql, Link, StaticQuery } from 'gatsby';
import React from 'react';
import { css } from '@emotion/react';
import { FixedObject } from 'gatsby-image';

import {
  SiteTitleSmall,
} from '../../styles/shared';

import config from '../../website-config';

import '../../assets/scss/main.scss';


interface SiteNavLogoProps {
  logo?: {
    childImageSharp: {
      fixed: FixedObject;
    };
  };
}

export const SiteNavLogo = () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        logo: file(relativePath: { eq: "img/ghost-logo.png" }) {
          childImageSharp {
            fixed(quality: 100 width: 500) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data: SiteNavLogoProps) => (
      <div className="flex">
       <SiteTitleSmall id="navlogo" className="site-title">
       <p>Blog.</p><a href="https://www.jcamargo.co/" className="logo"><strong>jcamargo.co</strong></a>                
       </SiteTitleSmall>
       </div>
    )}
  />
);
