export const colors = {
  blue: '#242943',
  green: '#a4d037',
  purple: '#ad26b4',
  yellow: '#fecd35',
  red: '#f05230',
  darkgrey: '#15171A',
  midgrey: '#738a94',
  lightgrey: '#c5d2d9',
  whitegrey: '#e5eff5',
  pink: '#fa3a57',
  brown: '#a3821a',
  darkmode: '#242943',
  // Colors from sass template repeated here for conveniency with casper template way of defining styled components
  bg:					'#242943',
  bg_alt:				'#2a2f4a',
  fg:					'#ffffff',
  fg_bold:			'#ffffff',
  fg_light:			'rgba(244,244,255,0.2)',
  border:				'rgba(212,212,255,0.1)',
  border_bg:			'rgba(212,212,255,0.035)',
  highlight:			'#9bf1ff',
  accent1:			'#6fc3df',
  accent2:			'#8d82c4',
  accent3:			'#ec8d81',
  accent4:			'#e7b788',
  accent5:			'#8ea9e8',
  accent6:			'#87c5a4',
  // darkmode: color(var(--darkgrey) l(+2%)),
};
